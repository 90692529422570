<template>
  <div>
    <b-card-group deck>
      <b-card
          header="Thông tin cài đặt - Chiến dịch sinh viên"
          header-tag="header"
      >
        <b-form >
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="campaign_title"
                  label-for="input-1"
                  label="Tên chiến dịch (*):"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.title"
                    placeholder="Tên chiến dịch "
                    :class="[{ 'is-invalid': form.errors.has('title') }]"
                ></b-form-input>
              </b-form-group>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Mô tả (nếu có):"

              >
                <b-form-textarea
                    v-model="form.description"
                    :class="[{ 'is-invalid': form.errors.has('description') }]"
                    placeholder="Nhập mô tả..."
                    rows="3"
                    max-rows="6"
                >

                </b-form-textarea>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Banner (*):"  >
                <div style="display: flex" >
                  <div style="height: 30px; margin-right: 20px" v-show="form.banner.length >0">
                    <img
                        style="width: 38px; height: 38px"
                        :src="form.banner"
                        alt=""
                    />
                  </div>
                  <b-form-file
                      @change="onImageChange($event)"
                      :required="form.banner.length > 0?false:true"
                      placeholder="chọn banner"
                      accept="image/*"
                      type="file"
                  ></b-form-file>
                </div>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Số lượt giới thiệu tối đa cho 1 sinh viên:"
                  label-for="input-22211"

              >
                <b-form-input
                    id="input-22211"
                    v-model="form.max_in_camp"
                    type="number"
                    min="0"
                    placeholder="Số lượt nhận quà tối đa trong ngày"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Kịch bản phát quà mặc định(*):"
                  label-for="input-1"

              >
                <b-form-select v-model="form.script_id" :class="[{ 'is-invalid': form.errors.has('script_id') }]" :options="scripts">
                </b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <b-form-group
                  id="input-group-4"
                  label="Thời gian bắt đầu(*):"
                  label-for="d3"

              >
                <date-picker
                    id="d3"
                    name="dp3"
                    v-model="form.valid_at"
                    type="datetime"
                    placeholder="Chọn thời gian"
                    :input-attr="{required: 'true',valid_at:''}"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-type="format"
                ></date-picker>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                  id="input-group-4"
                  label="Thời gian kết thúc(*):"
                  label-for="d3"
              >
                <date-picker
                    id="d3"
                    name="dp3"
                    v-model="form.expired_at"
                    type="datetime"
                    placeholder="Chọn thời gian"
                    :input-attr="{required: 'true',expired_at:''}"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-type="format"
                ></date-picker>
              </b-form-group>
            </div>
          </div>
          <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Thử thách (*):"
                  label-for=""

              >
                <b-form-select v-model="form.challenge_id" :class="[{ 'is-invalid': form.errors.has('challenge_id') }]" :options="challenges">
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-1">
              Kích hoạt
            </div>
            <div class="col-md-3">

              <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.status"
                  value="1"
                  unchecked-value="0"
                  size="lg"
              >

              </b-form-checkbox>
            </div>
          </div>
          <hr>

          <b-card-group deck>
            <b-card
                header="Cấu hình quà chi tiết theo trường và thời gian"

                header-tag="header"
            >
              <b-form >
                <div class="row mb-4">
                  <div class="col-md-12">
                    <u class="text-info">Lưu ý: đối với những Trường Đại Học không được cài đặt dưới đây hệ thống sẽ phát theo kịch bản phát quà mặc định đã cài đặt ở trên</u>
                  </div>
                </div>
                <div class="row" v-for="(item ,index) in configs" v-bind:key="index">
                  <button title="Xóa" type="button" class="" @click="removeConfigItem(index)"><i class="icon-1x flaticon2-trash"></i></button>
                  <div class="col-md-3">
                    <b-form-group
                        :id="`configs_ids ${index + 1}`"
                        :label-for="`configs_ids ${index + 1}`"
                        label="Chọn trường(*):"
                    >
                      <multiselect
                          v-model="configs[index].universities_ids"
                          :options="universities"
                          :multiple="true"
                          label="name"
                          track-by="code"
                      ></multiselect>

                    </b-form-group>
                  </div>
                  <div class="col-md-3">
                    <b-form-group
                        :id="`configs_script ${index + 1}`"
                        :label-for="`configs_script ${index + 1}`"
                        label="Chọn kịch bản(*):"
                    >
                      <b-form-select v-model="configs[index].script_id" :class="[{ 'is-invalid': form.errors.has('script_id') }]" :options="scripts"></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-1">
                    <b-form-group
                        :id="`configs_script ${index + 1}`"
                        :label-for="`configs_script ${index + 1}`"
                        label="Ko giớ hạn"
                    >
                      <b-form-checkbox
                          :id="`configs_time-${index + 1}`"
                          v-model="configs[index].is_check_time"
                          value="1"
                          unchecked-value="0"
                          size="lg"
                      >
                      </b-form-checkbox>
                    </b-form-group>




                  </div>
                  <div class="col-md-2" v-if="configs[index].is_check_time == 0">
                    <b-form-group
                        :id="`configs_start-${index + 1}`"
                        :label-for="`date-pk-start-${index + 1}`"
                        label="Ngày bắt đầu(*):"
                    >
                      <date-picker
                          :id="`date-pk-start-${index + 1}`"
                          :name="`date-pk-start-${index + 1}`"
                          v-model="configs[index].date_start"
                          type="datetime"
                          placeholder="Chọn thời gian"
                          :input-attr="{required: 'true'}"
                          format="YYYY-MM-DD HH:mm:ss"
                          value-type="format"
                      ></date-picker>
                    </b-form-group>
                  </div>
                  <div class="col-md-2"  v-if="configs[index].is_check_time == 0">
                    <b-form-group
                        id="campaign_title"
                        :label-for="`date-pk-end-${index + 1}`"
                        label="Ngày kết thúc(*):"
                    >
                      <date-picker
                          :id="`date-pk-end-${index + 1}`"
                          :name="`date-pk-end-${index + 1}`"
                          v-model="configs[index].date_end"
                          type="datetime"
                          placeholder="Chọn thời gian"
                          :input-attr="{required: 'true'}"
                          format="YYYY-MM-DD HH:mm:ss"
                          value-type="format"
                      ></date-picker>
                    </b-form-group>
                  </div>

                </div>


              </b-form>
              <div class="row">
                <div class="col-md-12">
                  <a href="javascript:;" @click="addConfigs()">Thêm cấu hình</a>
                </div>
              </div>
            </b-card>


          </b-card-group>



          <hr>



          <div class="row">
            <div class="col-md-12">
              <b-button variant="success"  style="margin-right: 10px;" @click="onSubmit">Lưu</b-button>
              <router-link
                  :to="'/refer'"
              >
                <b-button variant="outline-primary">Hủy</b-button>
              </router-link>

            </div>
          </div>

        </b-form>
      </b-card>


    </b-card-group>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { Form } from 'vform';
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";

import RepositoryFactory from "@/core/repositories/repositoryFactory";
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");
const CampaignRepository = RepositoryFactory.get("Campaign");
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component("multiselect", Multiselect);
export default {
  name: "FormComboVoucherCampaign",
  mixins: [Common],
  components: {DatePicker},
  data() {
    return {
      form: new Form({
        title: '',
        description: '',
        banner: '',
        valid_at: '',
        expired_at: '',
        max_in_camp: '',
        script_id: '',
        status: '',
        challenge_id: '',
        data_config: '',
      }),
      show: true,
      images: '',
      scripts: [],
      challenges: [],
      configs: [

      ],
      universities: [],
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cài đặt chiến dịch" },
    ]);
  },

  methods: {
    removeConfigItem(index){
      this.configs.splice(index,1)
    },
    addConfigs(){
      let config = {
        universities_ids:[],
        script_id:'',
        date_start:'',
        date_end:'',
        is_check_time:1,
      }
      this.configs.push(config);

    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);
      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form.banner = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async getDataCreate() {
      let response = await WalletCampaignRepository.getListAllScripts();
      if (response.data.data){
        response.data.data.forEach(item=>{
          this.scripts.push(
              {
                value:item.id,
                text:item.event.name,
              }
          )
        })
      }
    },
    async getListChallenges() {
      let response = await CampaignRepository.getListAllChallenge();
      if (response.data.data){
        response.data.data.forEach(item=>{
          this.challenges.push(
              {
                value:item.id,
                text:item.title,
              }
          )
        })
      }
    },
    isEmpty(value){
      return (typeof value === "undefined" || value === null || value === '');
    },

    reBuildDataConfig(){
      let list = []
      this.configs.forEach(config=>{
        let data = {
          universities_ids:  config.universities_ids.map(item => item.id).join(','),
          script_id: config.script_id,
          is_check_time: config.is_check_time,
          date_start: config.date_start,
          date_end: config.date_end
        }
        list.push(data)

      })
      return JSON.stringify(list)
    },
    onSubmit () {
      this.$bus.$emit("show-loading", true);
      this.form.data_config = this.reBuildDataConfig();
      this.form.submit('post', '/refer-student/config')
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: response.data.message,
              });
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async loadReferInfo () {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await WalletCampaignRepository.loadReferStudentInfo();
        this.$bus.$emit("show-loading", false);
        if (response.data.error_code === 0) {
          let data = response.data.data
          this.form.fill(data)
          this.universities = data.universities;
          data.config.forEach(item=>{
            if (item.university_id.toString().includes(',')){
              let university_ids = item.university_id.split(',').map(element => parseInt(element, 10))
              item.universities_ids = this.universities.filter(univer => university_ids.includes(univer.id));
            }else {
              item.universities_ids = this.universities.filter(univer => item.university_id == univer.id);
            }

            this.configs.push(item)
          })

        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$bus.$emit("show-loading", false);
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
  },
  created() {
    this.getDataCreate();
    this.getListChallenges();
    this.loadReferInfo();
  },
  watch: {
    'form.type'() {
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

